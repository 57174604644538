import { ins } from 'src/api/axios'
import type {
    GetWorkAreaZoneActivationStatusResponse,
    GetWorkAreasByZoneIdResponse,
    WorkArea,
    WorkAreaZoneActivationStatus,
} from 'src/api/fm/workArea/workArea.model'
import { getHeaders } from 'src/api/headers'
import { getError } from 'src/api/utils'

/**
 * Related {@link https://warf.voiapp.io/catalog/fm/api/fm-api/definition#/workarea/createWorkArea API documentation}
 */
export const createWorkArea = async (workArea: WorkArea): Promise<void | Error> => {
    const url = '/v1/fm/work-areas'
    const headers = await getHeaders()

    return ins
        .post(url, workArea, { headers })
        .then(() => undefined)
        .catch(e => getError(e, 'response.data.errors[0]'))
}

/**
 * Related {@link https://warf.voiapp.io/catalog/fm/api/fm-api/definition#/workarea/updateWorkArea API documentation}
 */
export const updateWorkArea = async (workArea: WorkArea): Promise<void | Error> => {
    const url = `/v1/fm/work-areas/${workArea.id}`
    const headers = await getHeaders()

    return ins
        .put(url, workArea, { headers })
        .then(() => undefined)
        .catch(e => getError(e, 'response.data.errors[0]'))
}

/**
 * Related {@link https://warf.voiapp.io/catalog/fm/api/fm-api/definition#/workarea/deleteWorkArea API documentation}
 */
export const deleteWorkArea = async (workAreaId: string): Promise<void | Error> => {
    const url = `/v1/fm/work-areas/${workAreaId}`
    const headers = await getHeaders()

    return ins
        .delete(url, { headers })
        .then(() => undefined)
        .catch(e => getError(e, 'response.data.errors[0]'))
}

/**
 * Related {@link https://warf.voiapp.io/catalog/fm/api/fm-api/definition#/workarea/getWorkAreasByZoneId API documentation}
 */
export const getWorkAreasByZoneId = async (zoneId: string): Promise<WorkArea[] | Error> => {
    const url = `/v1/fm/work-areas/zone/${zoneId}`
    const headers = await getHeaders()

    return ins
        .get<GetWorkAreasByZoneIdResponse>(url, { headers })
        .then(res => mapGetWorkAreasByZoneId(res.data))
        .catch(e => getError(e, 'response.data.errors[0]'))
}

/**
 * Related {@link https://warf.voiapp.io/catalog/fm/api/fm-api/definition#/workarea/createWorkAreaZoneActivation API documentation}
 */
export const createWorkAreaZoneActivation = async (zoneId: string): Promise<void | Error> => {
    const url = `/v1/fm/work-areas/zone/${zoneId}/activation`
    const headers = await getHeaders()

    return ins
        .post(url, undefined, { headers })
        .then(() => undefined)
        .catch(e => getError(e, 'response.data.errors[0]'))
}

/**
 * Related {@link https://warf.voiapp.io/catalog/fm/api/fm-api/definition#/workarea/deleteWorkAreaZoneActivation API documentation}
 */
export const deleteWorkAreaZoneActivation = async (zoneId: string): Promise<void | Error> => {
    const url = `/v1/fm/work-areas/zone/${zoneId}/activation`
    const headers = await getHeaders()

    return ins
        .delete(url, { headers })
        .then(() => undefined)
        .catch(e => getError(e, 'response.data.errors[0]'))
}

/**
 * Related {@link https://warf.voiapp.io/catalog/fm/api/fm-api/definition#/workarea/getWorkAreaZoneActivationStatus API documentation}
 */
export const getWorkAreaZoneActivationStatusByZoneId = async (
    zoneId: string,
): Promise<WorkAreaZoneActivationStatus | Error> => {
    const url = `/v1/fm/work-areas/zone/${zoneId}/activation`
    const headers = await getHeaders()

    return ins
        .get<GetWorkAreaZoneActivationStatusResponse>(url, { headers })
        .then(res => mapGetWorkAreaZoneActivationStatus(res.data))
        .catch(e => getError(e, 'response.data.errors[0]'))
}

const mapGetWorkAreasByZoneId = (response: GetWorkAreasByZoneIdResponse): WorkArea[] => {
    return response.workAreas.map(w => ({
        id: w.id,
        zoneId: w.zoneId,
        name: w.name,
        area: w.area,
        accountableOrganizationId: w.accountableOrganizationId,
        rebalanceAccountableOrganizationId: w.rebalanceAccountableOrganizationId,
        createdAt: w.createdAt,
        updatedAt: w.updatedAt,
    }))
}

const mapGetWorkAreaZoneActivationStatus = (
    response: GetWorkAreaZoneActivationStatusResponse,
): WorkAreaZoneActivationStatus => {
    return {
        zoneId: response.zoneId,
        activated: response.activated,
    }
}
