import { type AccountSharingInfo, type DriversLicenseStatus } from 'src/api/fm/users/users.model'
import { type RiderPaymentDetails, type RiderVoiPass, type Contract } from 'src/api/fm/payments/payments.model'
import { type RideHistoryItem, type Ride } from 'src/api/fm/rentals/rentals.model'
import { type RiderVoucherDetails } from 'src/api/fm/discounts/discounts.model'
import { type NavigateFunction } from 'react-router'
import { type CurrentUser } from 'src/redux/auth/auth.types'
import { type BlockTypes } from 'src/pages/riderDetails/blockUserModal/BlockUserModal'
import { type FineItem } from 'src/api/fm/fineHistory/fines.model'

export const TOGGLE_END_RIDE_MODAL = 'TOGGLE_END_RIDE_MODAL'
export const GET_CURRENT_RIDER_FULL_PROFILE = 'GET_CURRENT_RIDER_FULL_PROFILE'
export const GET_ACCOUNT_SHARING_INFO = 'GET_ACCOUNT_SHARING_INFO'
export const GET_DISCOUNT_GROUPS = 'GET_DISCOUNT_GROUPS'
export const GET_RIDER_DISCOUNT = 'GET_RIDER_DISCOUNT'
export const GET_RIDER_VOI_PASS = 'GET_RIDER_VOI_PASS'
export const GET_RIDER_CONTRACTS = 'GET_RIDER_CONTRACTS'
export const GET_RIDER_PAYMENT_DETAILS = 'GET_RIDER_PAYMENT_DETAILS'
export const GET_RIDER_RIDE_HISTORY = 'GET_RIDER_RIDE_HISTORY'
export const GET_RIDE_HISTORY_ITEM = 'GET_RIDE_HISTORY_ITEM'
export const GET_RIDE_PARKING_PHOTO = 'GET_RIDE_PARKING_PHOTO'
export const SET_CURRENT_RIDER = 'SET_CURRENT_RIDER'
export const SET_ACCOUNT_SHARING_INFO = 'SET_ACCOUNT_SHARING_INFO'
export const SET_DISCOUNT_GROUPS = 'SET_DISCOUNT_GROUPS'
export const SET_RIDER_DISCOUNT = 'SET_RIDER_DISCOUNT'
export const SET_RIDER_VOI_PASS = 'SET_RIDER_VOI_PASS'
export const SET_RIDER_CONTRACTS = 'SET_RIDER_CONTRACTS'
export const SET_RIDER_PAYMENT_DETAILS = 'SET_RIDER_PAYMENT_DETAILS'
export const GIVE_RIDER_CREDITS = 'GIVE_RIDER_CREDITS'
export const GIVE_RIDER_DISCOUNTS = 'GIVE_RIDER_DISCOUNTS'
export const FORGIVE_RIDER_DEBT = 'FORGIVE_RIDER_DEBT'
export const EDIT_RIDER_DISCOUNT = 'EDIT_RIDER_DISCOUNT'
export const SET_RIDER_RIDE_HISTORY = 'SET_RIDER_RIDE_HISTORY'
export const SET_CURRENT_RIDE_HISTORY_ITEM = 'SET_CURRENT_RIDE_HISTORY_ITEM'
export const SET_RIDE_PARKING_PHOTO = 'SET_RIDE_PARKING_PHOTO'
export const RESET_RIDER_RIDE_HISTORY = 'RESET_RIDER_RIDE_HISTORY'
export const SET_RIDER_RIDE_HISTORY_DATE_FILTER = 'SET_RIDER_RIDE_HISTORY_DATE_FILTER'
export const SET_RIDER_RIDE_HISTORY_SOURCE_FILTER = 'SET_RIDER_RIDE_HISTORY_SOURCE_FILTER'
export const SET_ACTIVE_RIDE_RIDER = 'SET_ACTIVE_RIDE_RIDER'
export const SET_GUEST_RIDES = 'SET_GUEST_RIDES'
export const SUBMIT_END_RIDE = 'SUBMIT_END_RIDE'
export const BLOCK_RIDER = 'BLOCK_RIDER'
export const DELETE_RIDER = 'DELETE_RIDER'
export const GET_DRIVERS_LICENSE_STATUS = 'GET_DRIVERS_LICENSE_STATUS'
export const SET_DRIVERS_LICENSE_STATUS = 'SET_DRIVERS_LICENSE_STATUS'
export const DELETE_DRIVERS_LICENSE = 'DELETE_DRIVERS_LICENSE'
export const APPROVE_DRIVERS_LICENSE_DOCUMENT = 'APPROVE_DRIVERS_LICENSE_DOCUMENT'
export const APPROVE_DRIVERS_LICENSE_FACE_CHECK = 'APPROVE_DRIVERS_LICENSE_FACE_CHECK'
export const EXPIRE_RIDER_VOI_PASS = 'EXPIRE_RIDER_VOI_PASS'
export const ABANDON_CONTRACT = 'ABANDON_CONTRACT'
export const CANCEL_AND_REFUND_CONTRACT = 'CANCEL_AND_REFUND_CONTRACT'
export const STOP_CONTRACT_RENEWAL = 'STOP_CONTRACT_RENEWAL'
export const GET_RIDER_VOUCHER_DETAILS = 'GET_RIDER_VOUCHER_DETAILS'
export const SET_RIDER_VOUCHER_DETAILS = 'SET_RIDER_VOUCHER_DETAILS'
export const DELETE_RIDER_VOUCHERS = 'DELETE_RIDER_VOUCHERS'
export const UNBLOCK_RIDER_DEVICE_SWITCH = 'UNBLOCK_RIDER_DEVICE_SWITCH'
export const UPDATE_DATE_OF_BIRTH = 'UPDATE_DATE_OF_BIRTH'
export const DELETE_RIDER_PHONE = 'DELETE_RIDER_PHONE'
export const GET_RIDER_FINE_HISTORY = 'GET_RIDER_FINE_HISTORY'
export const SET_RIDER_FINE_HISTORY = 'SET_RIDER_FINE_HISTORY'
export const RESET_RIDER_FINE_HISTORY = 'RESET_RIDER_FINE_HISTORY'
export const SET_DELETE_USER_FAILED = 'DELETE_USER_FAILED'
export const REQUEST_ID_CHECK = 'REQUEST_ID_CHECK'

export interface RiderState {
    endRideModal: Ride | null
    currentRider: Record<string, any> | null
    rideHistory: RideHistoryItem[] | null
    currentRideHistoryItem: RideHistoryItem | null
    parkingPhoto: ParkingPhoto | null
    rideHistoryDateFilter: string | null
    rideHistorySourceFilter: string | null
    activeRide: Ride | null
    guestRides: Ride[] | null
    accountSharingInfo: AccountSharingInfo | null
    discountGroups: RiderDiscountGroup[] | null
    riderDiscount: RiderDiscount | null
    voiPass: RiderVoiPass | null
    contracts: Contract[] | null
    paymentDetails: RiderPaymentDetails | null
    driversLicenseStatus: DriversLicenseStatus | null
    voucherDetails: RiderVoucherDetails
    fineHistory: FineItem[] | null
    deleteUserFailed: boolean
    activityScore: number | null
}

export interface RiderDiscount {
    group_id: string
    id: string
    user_id: string
    valid_from: number
    valid_to: number | null
}

export interface RiderDiscountGroup {
    description: string
    id: string
    name: string
}

export interface ParkingPhoto {
    url: string
}

export interface ToggleEndRideModal {
    type: typeof TOGGLE_END_RIDE_MODAL
    payload: Ride | null
}

export interface GetCurrentRiderFullProfile {
    type: typeof GET_CURRENT_RIDER_FULL_PROFILE
    payload: string
}

export interface GetAccountSharingInfo {
    type: typeof GET_ACCOUNT_SHARING_INFO
    payload: string
}

export interface GetRideParkingPhoto {
    type: typeof GET_RIDE_PARKING_PHOTO
    payload: string
}

export interface GetDiscountGroups {
    type: typeof GET_DISCOUNT_GROUPS
}

export interface GetRiderDiscount {
    type: typeof GET_RIDER_DISCOUNT
    payload: string
}

export interface GetRiderVoiPass {
    type: typeof GET_RIDER_VOI_PASS
    payload: string
}

export interface GetRiderContracts {
    type: typeof GET_RIDER_CONTRACTS
    payload: string
}

export interface SetRiderContracts {
    type: typeof SET_RIDER_CONTRACTS
    payload: Contract[]
}

export interface GetRiderPaymentDetails {
    type: typeof GET_RIDER_PAYMENT_DETAILS
    payload: string
}

export interface GetDriversLicenseStatus {
    type: typeof GET_DRIVERS_LICENSE_STATUS
    payload: string
}

export interface SetCurrentRider {
    type: typeof SET_CURRENT_RIDER
    payload: Record<string, any> | null
}

export interface SetAccountSharingInfo {
    type: typeof SET_ACCOUNT_SHARING_INFO
    payload: AccountSharingInfo | null
}

export interface SetDiscountGroups {
    type: typeof SET_DISCOUNT_GROUPS
    payload: RiderDiscountGroup[] | null
}

export interface SetRiderDiscount {
    type: typeof SET_RIDER_DISCOUNT
    payload: RiderDiscount | null
}

export interface SetRiderVoiPass {
    type: typeof SET_RIDER_VOI_PASS
    payload: RiderVoiPass
}

export interface SetRiderPaymentDetails {
    type: typeof SET_RIDER_PAYMENT_DETAILS
    payload: RiderPaymentDetails
}

export interface SetDriversLicenseStatus {
    type: typeof SET_DRIVERS_LICENSE_STATUS
    payload: DriversLicenseStatus
}

export interface GiveRiderCredits {
    type: typeof GIVE_RIDER_CREDITS
    payload: {
        riderId: string
        amount: number
        note: Record<string, any>
    }
}

export interface GiveRiderDiscounts {
    type: typeof GIVE_RIDER_DISCOUNTS
    payload: {
        riderId: string
        name: string
        // description: string
        internalName: string
        expiresAt: number
        amount: number
        credits: number
        unlockFeeDiscountPercent: number
        rideFeeDiscountPercent: number
        rideFeeDiscountMinutes: number
        note: Record<string, any>
    }
}

export interface ForgiveRiderDebt {
    type: typeof FORGIVE_RIDER_DEBT
    payload: { riderId: string }
}

export interface EditRiderDiscount {
    type: typeof EDIT_RIDER_DISCOUNT
    payload: { riderId: string; discountGroupId: string | null; validTo: number | null }
}

export interface SetRiderRideHistory {
    type: typeof SET_RIDER_RIDE_HISTORY
    payload: RideHistoryItem[] | null
}

export interface SetRideParkingPhoto {
    type: typeof SET_RIDE_PARKING_PHOTO
    payload: ParkingPhoto | null
}

export interface DeleteDriversLicense {
    type: typeof DELETE_DRIVERS_LICENSE
    payload: string
}

export interface ApproveDriversLicenseDocument {
    type: typeof APPROVE_DRIVERS_LICENSE_DOCUMENT
    payload: string
}

export interface ApproveDriversLicenseFaceCheck {
    type: typeof APPROVE_DRIVERS_LICENSE_FACE_CHECK
    payload: string
}

export interface DeleteRider {
    type: typeof DELETE_RIDER
    payload: { id: string; reason: string; navigateFn: NavigateFunction }
}

export interface RequestIDCheck {
    type: typeof REQUEST_ID_CHECK
    payload: { reason: string; userIds: string[] }
}

export interface SubmitEndRide {
    type: typeof SUBMIT_END_RIDE
    payload: {
        ride: Ride | null
        user: CurrentUser | null
        data: { note: { title: string; desc: string }; minutes: number; type: string }
    }
}

export interface SetActiveRideRider {
    type: typeof SET_ACTIVE_RIDE_RIDER
    payload: Ride | null
}

export interface SetGuestRides {
    type: typeof SET_GUEST_RIDES
    payload: Ride[] | null
}

export interface GetRiderRideHistory {
    type: typeof GET_RIDER_RIDE_HISTORY
    payload: number
}

export interface GetRideHistoryItem {
    type: typeof GET_RIDE_HISTORY_ITEM
    payload: string
}

export interface SetCurrentRideHistoryItem {
    type: typeof SET_CURRENT_RIDE_HISTORY_ITEM
    payload: RideHistoryItem
}

export interface ResetRiderRideHistory {
    type: typeof RESET_RIDER_RIDE_HISTORY
}

export interface SetRiderRideHistoryDateFilter {
    type: typeof SET_RIDER_RIDE_HISTORY_DATE_FILTER
    payload: string | null
}

export interface BlockRider {
    type: typeof BLOCK_RIDER
    payload: { id: string; blockDesc: string; blockTypes: BlockTypes }
}

export interface ExpireRiderVoiPass {
    type: typeof EXPIRE_RIDER_VOI_PASS
    payload: { riderId: string; ridePlanId: string | undefined }
}

export interface AbandonContract {
    type: typeof ABANDON_CONTRACT
    payload: { riderId: string; contractId: string | undefined }
}

export interface CancelAndRefundContract {
    type: typeof CANCEL_AND_REFUND_CONTRACT
    payload: { riderId: string; contractId: string | undefined }
}

export interface StopContractRenewal {
    type: typeof STOP_CONTRACT_RENEWAL
    payload: { riderId: string; contractId: string | undefined }
}

export interface GetRiderVoucherDetails {
    type: typeof GET_RIDER_VOUCHER_DETAILS
    payload: string
}

export interface SetRiderVoucherDetails {
    type: typeof SET_RIDER_VOUCHER_DETAILS
    payload: RiderVoucherDetails
}

export interface DeleteRiderVouchers {
    type: typeof DELETE_RIDER_VOUCHERS
    payload: { riderId: string; voucherIds: string[] }
}

export interface UnblockRiderDeviceSwitch {
    type: typeof UNBLOCK_RIDER_DEVICE_SWITCH
    payload: { id: string; reason: string }
}

export interface UpdateDateOfBirth {
    type: typeof UPDATE_DATE_OF_BIRTH
    payload: { riderId: string; dateOfBirth: string }
}

export interface DeleteRiderPhone {
    type: typeof DELETE_RIDER_PHONE
    payload: string
}

export interface GetRiderFineHistory {
    type: typeof GET_RIDER_FINE_HISTORY
    payload: string
}

export interface SetRiderFineHistory {
    type: typeof SET_RIDER_FINE_HISTORY
    payload: FineItem[]
}

export interface ResetRiderFineHistory {
    type: typeof RESET_RIDER_FINE_HISTORY
}

export interface DeleteUserFailed {
    type: typeof SET_DELETE_USER_FAILED
    payload: boolean
}

export type RiderActions =
    | ToggleEndRideModal
    | GetCurrentRiderFullProfile
    | GetAccountSharingInfo
    | GetRideParkingPhoto
    | GetDiscountGroups
    | GetRiderDiscount
    | GetRiderVoiPass
    | GetDriversLicenseStatus
    | SetCurrentRider
    | SetAccountSharingInfo
    | SetDiscountGroups
    | SetRiderDiscount
    | SetRiderVoiPass
    | SetRiderPaymentDetails
    | SetDriversLicenseStatus
    | GiveRiderCredits
    | GiveRiderDiscounts
    | ForgiveRiderDebt
    | EditRiderDiscount
    | SetRiderRideHistory
    | SetRideParkingPhoto
    | DeleteDriversLicense
    | ApproveDriversLicenseDocument
    | ApproveDriversLicenseFaceCheck
    | DeleteRider
    | SubmitEndRide
    | SetActiveRideRider
    | SetGuestRides
    | GetRiderRideHistory
    | GetRideHistoryItem
    | SetCurrentRideHistoryItem
    | ResetRiderRideHistory
    | SetRiderRideHistoryDateFilter
    | BlockRider
    | ExpireRiderVoiPass
    | AbandonContract
    | CancelAndRefundContract
    | StopContractRenewal
    | GetRiderVoucherDetails
    | SetRiderVoucherDetails
    | DeleteRiderVouchers
    | UnblockRiderDeviceSwitch
    | UpdateDateOfBirth
    | DeleteRiderPhone
    | GetRiderFineHistory
    | SetRiderFineHistory
    | ResetRiderFineHistory
    | DeleteUserFailed
    | RequestIDCheck
    | GetRiderPaymentDetails
    | GetRiderContracts
    | SetRiderContracts
